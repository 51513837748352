import * as React from 'react';

import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';

export default withPrismicPreviewResolver(() => {
	return (
		<div>
			<h1>Loading preview…</h1>
		</div>
	);
});
